import { SignedOut, SignIn } from "@clerk/remix"
import { getAuth } from "@clerk/remix/ssr.server"
import { Box, Group, Image, Stack } from "@mantine/core"
import { useLoaderData } from "@remix-run/react"
import { LoaderFunctionArgs, redirect } from "@remix-run/server-runtime"

import Logo from "@kiosk/front/components/Logo"
import { Branding } from "@kiosk/front/lib/entities/company"
import { GreensterTheme, KioskTheme } from "@kiosk/front/theme"

import background from "./auth-background.png"

export async function loader(args: LoaderFunctionArgs) {
  const { userId } = await getAuth(args)

  const redirectURL = new URL(args.request.url).searchParams.get("redirect_url")

  // If the user is already logged in, redirect them to the dashboard.
  if (userId) {
    return redirect(redirectURL ?? "/dashboard")
  }

  const currentURL = new URL(args.request.url)

  return { currentURL: currentURL.toString(), redirectURL }
}

const fontFamilies: Record<Branding, string> = {
  KIOSK: KioskTheme.fontFamily,
  GREENSTER: GreensterTheme.fontFamily,
}

export default function WelcomePage() {
  const { currentURL, redirectURL } = useLoaderData<typeof loader>()

  const branding = currentURL.includes("greenster") ? "GREENSTER" : "KIOSK"

  return (
    <SignedOut>
      <Stack align="flex-start" h="100vh">
        <Group gap={0} h="100%" p={24} w="100vw">
          <Stack align="flex-start" h="100%" justify="center" w="50%">
            <Box h={28}>
              <Logo branding={branding} />
            </Box>

            <Stack align="center" h="100%" justify="center" w="100%">
              <SignIn
                // Hide the sign-up button on login form
                afterSignOutUrl="/sign-in"
                appearance={{
                  elements: { footerAction: { display: "none" } },
                  variables: {
                    fontFamily: fontFamilies[branding],
                  },
                }}
                fallbackRedirectUrl="/dashboard"
                forceRedirectUrl={redirectURL ?? "/dashboard"}
              />
            </Stack>
          </Stack>

          <Stack h="100%" w="50%">
            <Image
              alt="auth image"
              fit="cover"
              flex={1}
              h="100%"
              radius="md"
              src={background}
              w="100%"
            />
          </Stack>
        </Group>
      </Stack>
    </SignedOut>
  )
}
